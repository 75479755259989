import React, { Component, Fragment } from 'react'
import { graphql } from 'gatsby'
import { connect } from 'react-redux'
import SEO from '@/components/SEO/SEO'
import BetterIcon from '@/components/utils/BetterIcon'
import Subscribe from '@/components/utils/Subscribe'
/* eslint-disable no-unused-vars */
import {
	optimisedImage,
	relatedBlog,
	relatedAudio,
	relatedVideo,
	previewThumb
} from '@/queries/'
/* eslint-enable */

import twitterIcon from '@/icons/ui-twitter.svg'
import instagramIcon from '@/icons/ui-instagram.svg'
import facebookIcon from '@/icons/ui-facebook.svg'

import { setupPage } from '@/utils/common'

class ContactPage extends Component {
	constructor(props) {
		super(props)
		setupPage(props)
	}

	state = {
		email: '',
		showErrors: true
	}

	onChange = e => {
		const {
			target: { value }
		} = e

		this.setState({ email: value, showErrors: false })
	}

	render() {
		const {
			data: {
				globals: {
					globals: {
						talkToUs : {
							email: globalEmail,
						}
					}
				}
			},
			location
		} = this.props
		const { email, showErrors } = this.state

		return (
			<Fragment>
				<SEO title="Contact us" url={location.href} />

				<div className="wrapper py-5 lg:pb-3 lg:py-4">
					<h1 className="text-xlh-fluid text-white font-bold lowercase mb-2">
						Contact us
					</h1>
					<div className="lg:flex lg:flex-wrap">
						<div className="lg:w-12/24 flex lg:mb-0 ">
							<div className="bg-white w-full h-full p-1 lg:p-2 xl:pb-3-5">
								<h3 className="font-bold text-xxl-fluid mb-0-5 lowercase text-black mb-1">
									email us
								</h3>
								<a
									href={`mailto:${globalEmail}`}
									className="text-teal text-body-fluid leading-normal font-bold hover:text-orange focus:text-orange trans outline-none"
								>
									{globalEmail}
								</a>
								<div>
									<div className="mt-1 flex items-center">
										<a
											className="mr-1 font-bold text-orange no-underline outline-none hover:text-black focus:text-black trans"
											href="https://twitter.com/onstraightline"
										>
											<BetterIcon icon={twitterIcon} className="w-30" />
											<span className="visuallyhidden">Twitter account for we are straightline</span>
										</a>
										<a
											className="mr-1 font-bold text-orange no-underline outline-none hover:text-black focus:text-black trans"
											href="https://www.instagram.com/wearestraightline/"
										>
											<BetterIcon icon={instagramIcon} className="w-30" />
											<span className="visuallyhidden">Instagram account for we are straightline</span>
										</a>
										<a
											className="text-orange font-bold no-underline outline-none hover:text-black focus:text-black trans"
											href="https://www.facebook.com/wearestraightline"
										>
											<BetterIcon icon={facebookIcon} className="w-30" />
											<span className="visuallyhidden">Facebook page for we are straightline</span>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className="lg:w-12/24 flex lg:mb-0 ">
							<Subscribe url="https://prisonradioassociation.us9.list-manage.com/subscribe/post-json?u=33932ee491848aaa8baef7370&id=d5e308def0">
								{({ handleSubmit, status, message }) => (
									<form
										className="bg-grey w-full h-full p-1 lg:p-2 xl:pb-4-5"
										action="https://prisonradioassociation.us9.list-manage.com/subscribe/post?u=33932ee491848aaa8baef7370&id=d5e308def0"
										method="post"
										noValidate
										onSubmit={e => {
											e.preventDefault()

											handleSubmit({ EMAIL: email })
											this.setState({ showErrors: true })
										}}
									>
										<span className="font-bold text-xxl-fluid mb-0-5 lowercase text-white mb-1 block mb-1-5 w-full">
											Sign up for updates
										</span>
										<div className="flex items-stretch">
											<label htmlFor="signup-email" className="flex-1">
												<input
													type="email"
													name="signup-email"
													id="signup-email"
													placeholder="email address"
													className="text-white w-full bg-tundora w-full h-full px-0-75 py-0-5 text-xxs-fluid"
													value={email}
													onChange={this.onChange}
												/>
											</label>
											<div
												style={{ position: 'absolute', left: '-5000px' }}
												aria-hidden="true"
											>
												<input
													type="text"
													name="b_33932ee491848aaa8baef7370_d5e308def0"
													tabIndex="-1"
													defaultValue=""
												/>
											</div>
											<div className="lg:w-130">
												<button
													type="submit"
													className="w-full text-xxs-fluid bg-orange hover:bg-teal p-0-75 inline-flex items-center justify-center trans trans-bg trans-slow "
												>
													{status === 'loading'
														? 'sending'
														: status === 'success'
															? 'sent'
															: 'submit'}
												</button>
											</div>
										</div>
										{showErrors &&
											message && (
											<div
												className={`${
													status === 'error' ? 'text-error' : 'text-teal'
												} text-xs-fluid mt-2`}
											>
												{message}
											</div>
										)}
									</form>
								)}
							</Subscribe>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({ player: { setPageType, setAudioBarVisibility } }) => ({
	setPageType,
	setAudioBarVisibility
})

export default connect(
	mapState,
	mapDispatch
)(ContactPage)

export const pageQuery = graphql`
	query ContactQuery {
		globals: craft {
			globals {
				talkToUs {
					email
				}
			}
		}
	}
`
